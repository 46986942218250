body, h1, h2, p, ul, li {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
}

nav {
    background-color: #34495e;
    padding: 10px;
    color: #ecf0f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav a {
    color: #ecf0f1;
    text-decoration: none;
    margin: 0 10px;
}

nav div {
    display: flex;
    align-items: center;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-title{
    margin-top: 50px;
    margin-bottom: 100px;
}

.content {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.content-child {
    margin-bottom: 20px;
}

.real-collection {
    background-color: green;
    margin-right: 20px;
}

.real-collection:hover {
    background-color: rgba(0, 128, 0, 0.7);
    margin-right: 20px;
}

.fake-collection {
    background-color: red;
}

.fake-collection:hover {
    background-color: rgba(255, 0, 0, 0.7);
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table th, table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
}

form>p{
    margin-bottom: 20px;
}

form>button {
    background-color: gray;
    color: #000000;
    border: 1px solid gray;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    margin: 20px;
}

input, button {
    margin-bottom: 10px;
    padding: 8px;
}

button {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

button:hover {
    background-color: #455b72;
    color: #ffffff;
}

td>button {
    background-color: transparent;
    color: #000000;
    border: 1px solid gray;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.report-details {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.report-details>p {
    margin: 20px;
}

img {
    width: 50px;
} 

